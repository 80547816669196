import React from "react"
import { connect } from "react-redux"

import PaymentStructure from "../../shared/paymentStructure";
import styles from "./styles.module.css";
import PriceTotal from "../../shared/priceTotal";
import { decimal } from "../../common/numFormat";
import { accountPriceInfo, getUrlReferralCode } from "../../services/orderProcessing";
import { getToken } from "../../services/auth";
import { navigate } from "gatsby";
import { getAccountPrefix } from "../../common/gFunctions";
import PlanInformation from "../../shared/planInformation";
import Tooltip from "../../shared/Tooltip";
import ProceedButton from "../proceedButton";
import { Order_Type, System_Type } from "../../common/constants";

var dateFormat = require('dateformat');

class UpgradeOptionsComponent extends React.Component {
  constructor(props) {
      super(props);
      const token = getToken();

      this.state = {
        ...this.props.upgradePriceInfo,
        token,
        priceDetails: null,
        tempDiscountCode: "",
        discountCode: "",
        OrderType: 2,
        proceedLoading: false
      };

      if (this.props.selectedProduct) {
        var selectedProduct = this.props.selectedProduct;
        this.state = {
          ...this.state,
          OrderType: selectedProduct.orderType || this.state.OrderType
        }
      }
      console.log("test...",this.state);
  }



  componentWillUnmount() {
    document.body.classList.remove('sel-background'); 
  }

  componentDidMount() {
    this.updatePriceInfo();

    var code = getUrlReferralCode();

    if (code && code !== '' && code !== 'undefined' && code !== 'null') {
        this.setState({tempDiscountCode: code, discountCode: code, codeFromUrl: true});
        // setUrlReferralCode('');
        setTimeout(() => {
            this.applyDiscountCode();
        })
    }

    if (!this.props.existingPlan) {
      navigate(getAccountPrefix(this.state.token)+'/order/product');
    }
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });

    setTimeout(() => {
        this.updatePriceInfo();
    })
  }

  updatePriceInfo = () => {
    var choice = this.state.OrderType===2 ? this.state.Regular : this.state.Extend;
    if (choice) {
      accountPriceInfo(choice.NewPlan.ProductId,choice.NewPlan.SystemType,choice.NewPlan.NumDesktopUsers,choice.NewPlan.NumCloudUsers,choice.NewPlan.NumFamiliesTotal,-1,choice.NewPlan.AdditionalServicesString,this.state.discountCode,this.state.OrderType,getToken(),choice.IsApplyReferralPoints,choice.NewPlan.CustomAUMLimit)
        .then(({error,response}) => {
            if (error.status>=400) {
                console.log(error);
            } else {
                this.setState({
                    priceDetails: response
                })
            }
        });
    }
  }

  proceed = () => {
    this.setState({proceedLoading: true});
    this.props.setSelectedProduct({
      ...this.props.selectedProduct,
      orderType: this.state.OrderType
    })
    if (this.state.priceDetails) {
      this.props.setPriceDetails(this.state.priceDetails);
      navigate("/account/order/details");
    }
  }

  onEditClick = () => {
    navigate(getAccountPrefix(this.state.token)+'/order/product');
  }

  applyDiscountCode = () => {
    this.setState({discountCode: this.state.tempDiscountCode });
    setTimeout(() => {
        this.updatePriceInfo();
    });
  }

  cancelDiscountCode = () => {
    this.setState({discountCode: "", tempDiscountCode: ""});
    setTimeout(() => {
        this.updatePriceInfo();
    });
  }

  render() {
    const priceDetails = this.state.priceDetails;
    const DiscountFields = this.state.priceDetails && this.state.priceDetails.DiscountFields;
    console.log(priceDetails,DiscountFields,'DiscountFields')

    var regularUpgradeTooltipText = "If you select this option, your New Plan will be valid only until your current expiry date. You're only paying the difference in value between your New Plan and Current Plan, and you will have to renew your subscription again at License Expiry.";
    var regularUpgradeTooltipHeader = "Pay the difference between your New Plan and Current Plan";
    if (!this.state.Extend && this.state.OrderType == Order_Type.REGULAR_UPGRADE && priceDetails) {
      var planDifference = priceDetails.NewPlan.ProductAmount - priceDetails.ExistingPlan.ProductAmount;
      var adjustmentAmount = priceDetails.Amount - planDifference;
      if (adjustmentAmount > 0) {
        regularUpgradeTooltipHeader = "Cost breakdown: ";
        regularUpgradeTooltipText = [
          `1 year upgrade fee to your new MProfit plan = Rs. ${decimal(planDifference, 2)}`,
          `Adjustment of new plan till current expiry date (${dateFormat(priceDetails.NewPlan.LicenseExpDt, 'dd-mm-yyyy')}) = Rs. ${decimal(adjustmentAmount, 2)}`,
          `Total cost of upgrade = Rs. ${decimal(priceDetails.Amount, 2)}`,
        ]
      }
    }
    if (this.state.Regular && this.props.existingPlan) {
      return (
        <>
          <section className={styles.paymentFTU}>
            <div className={["container", styles.pay_container].join(" ")}>
                <div className="row">
                  <PaymentStructure progress={30}/>
                  <div className={["tab-content", styles.payContent, "text-center", "w-100"].join(" ")}>
                    <div className={["tab-pane", "container", "select_product", "active"].join(" ")} id="select_product">
                      <h2>
                        Upgrade Options
                      </h2>
                      <PlanInformation existingPlan={this.props.existingPlan}/>
                      <PlanInformation priceDetails={this.state.Regular} onEditClick={this.onEditClick} isUpgradePage={true}/>
                      <div className={[styles.form_container, "text-left", "shadow-sm", "bg-white", "rounded", "p-md-5"].join(" ")}>
                        <UpgradeItem optionNumber={1} showOptionNumber={this.state.Extend} value={2} Amount={this.state.Regular.Amount} LicenseExpDt={this.state.Regular.NewPlan.LicenseExpDt} isCurrent={true} handleInputChange={this.handleInputChange} currentValue={this.state.OrderType} tooltipText={regularUpgradeTooltipText} tooltipHeader={regularUpgradeTooltipHeader}/>
                        {
                          this.state.Extend &&
                          <UpgradeItem optionNumber={2} value={3} showOptionNumber={true} Amount={this.state.Extend.Amount} LicenseExpDt={this.state.Extend.NewPlan.LicenseExpDt} isCurrent={false} handleInputChange={this.handleInputChange} currentValue={this.state.OrderType} tooltipText={["If you select this option, you are making a full 1 year payment on your New Plan.","If you still have days remaining on your Current Plan, they will be adjusted towards your New Plan. This way you will not lose any unused balance and can enjoy MProfit for longer without worrying about making a payment again in the near future."]} tooltipHeader={"Switch to your New Plan with an early renewal"}/>
                        }
                        {
                          priceDetails &&
                          <PriceTotal Amount={priceDetails.Amount} DiscountedAmount={priceDetails.DiscountedAmount} TotalTax={priceDetails.TotalTax} Discount={priceDetails.Discount} FinalAmount={priceDetails.FinalAmount} LicenseExpDt={priceDetails.NewPlan.LicenseExpDt} applyDiscountCode={this.applyDiscountCode} cancelDiscountCode={this.cancelDiscountCode} handleInputChange={this.handleInputChange} DiscountCode={this.state.discountCode} TempDiscountCode={this.state.tempDiscountCode} DiscountFields={DiscountFields} SelectedUpgradeOption={this.state.Extend ? this.state.OrderType-1 : undefined} showCouponCode={false} codeFromUrl={this.state.codeFromUrl}/>
                        }
                        {/* <div className="form-check">
                          <input type="checkbox" className="form-check-input" id="Check1" />
                          <label className={["form-check-label", styles.lbl_chk].join(" ")} htmlFor="Check1">I accept all terms in MProfit's  <a href="#">Terms of Use</a> and <a href="#">Privacy Policy</a></label>
                        </div> */}
                        <ProceedButton disabled={false} onClick={this.proceed} loading={this.state.proceedLoading}/>
                        {/* <button onClick={()=>{this.proceed()}} className={["btn", "btn-block", "btn-primary", "blue_btn"].join(" ")}>Proceed</button> */}
                        <span onClick={()=>this.onEditClick()} className={["btn", "light_btn", "btn-block"].join(" ")}> <i className={["fa", "fa-angle-left", "mr-1"].join(" ")} aria-hidden="true"></i>Back </span>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )
    } else {
      return <></>
    }
  }
}

const UpgradeItem = ({optionNumber, value, isCurrent, Amount, LicenseExpDt, handleInputChange, currentValue, tooltipText, tooltipHeader, showOptionNumber}) => {
  const isActive = value == currentValue;
  return (
    <div className={["form-group", "fgroup"].join(" ")}>
      <label>{showOptionNumber ? 'Option '+optionNumber : 'Upgrade Details'}</label>
      <span className="qcircle" data-toggle="tooltip" data-placement="right" id={'upgradeLabel'+optionNumber}>?</span>
      <Tooltip text={tooltipText} header={tooltipHeader} target={'upgradeLabel'+optionNumber}/>
      <div className={["custom-control", styles.customRadio].join(" ")}>
        <input type="radio" id={'upgradeRadio'+optionNumber} name="OrderType" value={value} className={[styles.customControlInput, "custom-control-input"].join(" ")} onChange={(event)=>handleInputChange(event)} checked={currentValue===value}/>
        <label className={[styles.customControlLabel,isActive && styles.activeOption].join(" ")} htmlFor={'upgradeRadio'+optionNumber}>
          {/* {'Pay Rs. ' + decimal(Amount) + ' until ' + (isCurrent ? 'your current expiry date (' : '' ) + dateFormat(LicenseExpDt,'d mmmm, yyyy') + (isCurrent ? ')' : '')} */}
          <div className="d-flex justify-content-between">
            <span>{`Rs. ${decimal(Amount)}`}</span>
            <span>{`Expiry: ${dateFormat(LicenseExpDt,'d mmmm, yyyy')}`}</span>
          </div>
        </label>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    selectedProduct: state.order.SelectedProduct,
    upgradePriceInfo: state.order.UpgradePriceInfo,
    existingPlan: state.order.OrderPlacementDetails && state.order.OrderPlacementDetails.Plan
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setSelectedProduct: (selectedProduct) => dispatch({type: 'SET_SELECTED_PRODUCT', data: selectedProduct}),
    setPriceDetails: (priceDetails) => dispatch({ type: `SET_ORDER_PRICE_DETAILS`, data: priceDetails })
  }
}

const UpgradeOptions = connect(
  mapStateToProps,
  mapDispatchToProps
)(UpgradeOptionsComponent)  

export default UpgradeOptions