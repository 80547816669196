import React from "react"
import UpgradeOptions from "../../../components/upgradeOptions";
import PrivateRoute from "../../../components/privateRoute";

class UpgradePage extends React.Component {
    render() {
        return (
            <PrivateRoute path="/account/order/upgrade" component={UpgradeOptions} location={this.props.location} showSingleFooter={true} seoFields={seoFields}/>
        )
    }
}

const seoFields = {
    description: "",
    // lang,
    // meta,
    // keywords,
    title: "Buy Now",
}

export default UpgradePage